@font-face {
  font-family: "main";
  src: url("../public/font.otf");
}

html,
body,
#root {
  margin: 0;
  font-family: "main", sans-serif;
  min-height: 100dvh;
  -webkit-tap-highlight-color: transparent;
}

#root {
  display: flex;
  flex-direction: column;
  background-color: #cdcff3;
}

h1,
h2,
h3 {
  margin: 0;
  font-weight: normal;
}

h1 {
  font-size: 1.5em;
}

h2 {
  font-size: 1.2em;
}

h3 {
  font-size: 1em;
}

::view-transition-old(content) {
  animation: 500ms cubic-bezier(0.4, 0, 1, 1) both fade-out,
    500ms cubic-bezier(0.4, 0, 0.2, 1) both slide-to-left;
}

::view-transition-new(content) {
  animation: 500ms cubic-bezier(0, 0, 0.2, 1) 90ms both fade-in,
    500ms cubic-bezier(0.4, 0, 0.2, 1) both slide-from-right;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
}

@keyframes fade-out {
  to {
    opacity: 0;
  }
}

@keyframes slide-from-right {
  from {
    transform: translateX(100%);
  }
}

@keyframes slide-to-left {
  to {
    transform: translateX(-100%);
  }
}
